import React, { useEffect, useState, useRef, useFormContext } from "react";
import { CognitoAuth } from "../Utils/cognito-auth";
import { useAuth } from "../Utils/AuthContext";
import Posts from "./Posts/Posts";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./Forum.css";
import {
  Row,
  Col,
  Form,
  Button,
  FormControl,
  Container,
} from "react-bootstrap";

function Forum() {
  const { name, email } = useAuth();

  const emailInputSigninRef = useRef(null);
  const emailInputSigninErrorRef = useRef(null);
  const emailInputRegisterErrorRef = useRef(null);

  const signInButtonRef = useRef(null);
  const registerButtonRef = useRef(null);
  const verifyButtonRef = useRef(null);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [validatedSignin, setValidatedSignin] = useState(false);
  const [validatedRegister, setValidatedRegister] = useState(false);
  const [errors, setErrors] = useState({});
  const [activeSection, setActiveSection] = useState("signin");
  const {
    handleSignin,
    setPageElements,
    register,
    loadPosts,
    openPostModal,
    handleVerify,
  } = CognitoAuth({ setIsRegistered, setValidatedSignin, setValidatedRegister });

  useEffect(() => {
    if (email && name) {
      setIsAuthenticated(true);
    }
  });

  const checkAuthentication = async () => {
    // If authenticated, load posts
    if (isAuthenticated) {
      loadPosts();
    }
  };

  function handleSectionChange(section) {
      setActiveSection(section);
      resetOtherSectionValidation(section);
  }

  function resetOtherSectionValidation(currentSection) {
    if (currentSection === "signin") {
        setValidatedRegister(false);
        setErrors({});
    } else if (currentSection === "register") {
        setValidatedSignin(false);
        setErrors({});
    }
  }

  function findFormErrors() {
      const newErrors = {};

      if (activeSection === "signin") {
          // Check email pattern for Sign In form
          const emailSignin = $("#emailInputSignin").val();
          if (!emailPattern.test(emailSignin)) {
              newErrors.emailSignin = "Please enter a valid email address.";
          }
      }

      if (activeSection === "register") {
          // Check email pattern for Register form
          const emailRegister = $("#emailInputRegister").val();
          if (!emailPattern.test(emailRegister)) {
              newErrors.emailRegister = "Please enter a valid email address.";
          }
      }

      return newErrors;
  }

  function handleSubmitSignin(event) {
      resetOtherSectionValidation("signin");
      const form = event.currentTarget;

      // Prevent default submission
      event.preventDefault();

      // Check built-in form validity
      if (form.checkValidity() === false) {
          event.stopPropagation();
      }

      // Find custom errors
      const newErrors = findFormErrors();

      // If no custom errors and form is valid, submit the form
      if (Object.keys(newErrors).length === 0 && form.checkValidity()) {
          handleSignin(event);
          setValidatedSignin(true);
      } else {
          // Set the errors and ensure form feedback is shown
          setErrors(newErrors);
          setValidatedSignin(true);
      }
  }

  function handleRegister(event) {
      resetOtherSectionValidation("register");
      const form = event.currentTarget;

      // Prevent the default form submission behavior
      event.preventDefault();

      // Check built-in form validity
      if (form.checkValidity() === false) {
          event.stopPropagation();
          setValidatedRegister(true);
          return;
      }
        // Find custom errors
      const newErrors = findFormErrors();
      if (Object.keys(newErrors).length === 0 && form.checkValidity()) {
        // If no errors, proceed with form submission logic
        setValidatedRegister(true);

        var email = $("#emailInputRegister").val();
        var name = $("#nameInputRegister").val();
        var password = $("#passwordInputRegister").val();
        var password2 = $("#password2InputRegister").val();

        var onSuccess = function registerSuccess(result) {
            var cognitoUser = result.user;
            var confirmation =
                "Registration successful. Please check your email inbox or spam folder for your verification code and verify below.";
            if (confirmation) {
                alert(confirmation);
                var sectionsToHide = [
                    "#signInSection",
                    "#registerSection",
                    "#notAuthenticatedSection",
                ];
                var elementsToEnableAndClear = ["#verifySection"];
                var elementsToDisableAndClear = ["#signInSection", "#registerSection"];
                var sectionsToShow = ["#verifySection", "#verifyRegistrationSection"];
                var errorElementsToClear = [
                    "#emailSigninError",
                    "#emailRegisterError",
                    "#emailVerifyError",
                    "#passwordInputSignin"
                ];
                setIsRegistered(true);
                setPageElements(
                    sectionsToHide,
                    elementsToEnableAndClear,
                    elementsToDisableAndClear,
                    sectionsToShow,
                    errorElementsToClear
                );
            }
        };

        var onFailure = function registerFailure(err) {
        if (validatedRegister) {
            // Check if err and err.code are defined
            if (err && err.code && err.code.indexOf("UsernameExistsException") !== -1) {
                alert("err.code" + err.code);
                alert(err.message + ". Please Signin using your information.");
            } else if (err && err.message) {
                alert(err.message);
            } else {
                alert("An unknown error occurred during registration.");
            }
        }

        var sectionsToHide = [
            "#verifySection",
            "#verifyRegistrationSection",
            "#postsSection",
        ];
        var elementsToEnableAndClear = ["#signInSection", "#registerSection"];
        var elementsToDisableAndClear = ["#verifySection"];
        var sectionsToShow = [
            "#signInSection",
            "#registerSection",
            "#notAuthenticatedSection",
        ];
        var errorElementsToClear = [
            "#emailSigninError",
            "#emailRegisterError",
            "#emailVerifyError",
            "#passwordInputSignin"
        ];
        setIsRegistered(false);

        setPageElements(
            sectionsToHide,
            elementsToEnableAndClear,
            elementsToDisableAndClear,
            sectionsToShow,
            errorElementsToClear
        );
    };


        // If passwords match, attempt to register
        if (password === password2) {
            register(email, name, password, onSuccess, onFailure);
        } else {
            alert("Passwords do not match");
        }
      }
      else {
        setErrors(newErrors);
        setValidatedRegister(true);
    }
  }

  useEffect(() => {
    checkAuthentication();
  }, [isAuthenticated]);

  useEffect(() => {
    const createPostButton = document.querySelector("#createPostButton");

    const handleCreatePostButtonClick = () => {
      openPostModal();
    };
    if (createPostButton) {
      createPostButton.addEventListener("click", handleCreatePostButtonClick);

      // Cleanup: remove event listener when component unmounts
      return () => {
        createPostButton.removeEventListener(
          "click",
          handleCreatePostButtonClick
        );
      };
    }
  }, [openPostModal]);

  return (
    <>
      <div className="forum-page">
        {!isAuthenticated && (
          <div id="notAuthenticatedSection">
            <Row style={{ height: "20px" }}></Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Row style={{ height: "20px" }}></Row>

                <div
                  className="Auth-form-container   d-flex align-items-center justify-content-center"
                  id="signInSection"
                  style={{ width: "100%" }}
                >
                  <Form
                    noValidate
                    validated={validatedSignin}
                    role="form"
                    id="signinForm"
                    onSubmit={handleSubmitSignin}
                    className="Auth-form"
                  >
                    <div className="Auth-form-content">
                      <h6 className="text-heading Auth-form-title">Sign In</h6>
                      <Form.Group>
                        <Row style={{ padding: "5px" }}>
                          <Col
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            style={{ marginTop: "10px" }}
                          >
                            <Form.Label>Email Address</Form.Label>
                          </Col>
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <Form.Control
                              type="email"
                              ref={emailInputSigninRef}
                              id="emailInputSignin"
                              placeholder="Email"
                              autoComplete="emailInputSignin"
                              required
                              style={{ width: "90%" }}
                            />
                            <Form.Control.Feedback type="invalid">
                              <span
                                id="emailSigninError"
                                ref={emailInputSigninErrorRef}
                              >
                                {" "}
                                {errors.emailSignin ||
                                  "Please enter a valid email address."}
                              </span>
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Row style={{ padding: "5px" }}>
                          <Col lg={4} md={4} sm={4} xs={4}>
                            <Form.Label>Password</Form.Label>
                          </Col>
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <Form.Control
                              type="password"
                              minLength="6"
                              id="passwordInputSignin"
                              autoComplete="current-password"
                              placeholder="Password"
                              pattern=".*"
                              required
                              style={{ width: "90%" }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              <span>Please enter a valid Password.</span>
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                      </Form.Group>
                      <div className="d-grid gap-2 mt-3">
                        <Button
                          name="signInButton"
                          ref={signInButtonRef}
                          type="submit"
                          onClick={() => handleSectionChange("signin")}
                          className="btn btn-navy mx-auto"
                        >
                          Sign In
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div
                  className="Auth-form-container   d-flex align-items-center justify-content-center"
                  id="registerSection"
                >
                  <Form
                    noValidate
                    validated={validatedRegister}
                    id="registrationForm"
                    onSubmit={handleRegister}
                    className="Auth-form"
                  >
                    <div className="Auth-form-content">
                      <h6 className="text-heading Auth-form-title">Register</h6>
                      <Form.Group>
                        <Row style={{ padding: "5px" }}>
                          <Col lg={4} md={4} sm={4} xs={4}>
                            <Form.Label>Email Address</Form.Label>
                          </Col>
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <Form.Control
                              type="email"
                              id="emailInputRegister"
                              autoComplete="emailInputRegister"
                              placeholder="Email"
                              required
                              style={{ width: "90%" }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.emailRegister ||
                                "Please enter a valid email address."}
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Row style={{ padding: "5px" }}>
                          <Col lg={4} md={4} sm={4} xs={4}>
                            <Form.Label>Full Name</Form.Label>
                          </Col>
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <Form.Control
                              type="name"
                              id="nameInputRegister"
                              placeholder="Name"
                              pattern=".*"
                              required
                              style={{ width: "90%" }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid Full Name.
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Row style={{ padding: "5px" }}>
                          <Col lg={4} md={4} sm={4} xs={4}>
                            <Form.Label>Password</Form.Label>
                          </Col>
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <Form.Control
                              type="password"
                              minLength="6"
                              id="passwordInputRegister"
                              autoComplete="current-password"
                              placeholder="Password"
                              pattern=".*"
                              required
                              style={{ width: "90%" }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid Password.
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Row style={{ padding: "5px" }}>
                          <Col lg={4} md={4} sm={4} xs={4}>
                            <Form.Label>Confirm Password</Form.Label>
                          </Col>
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <Form.Control
                              type="password"
                              minLength="6"
                              id="password2InputRegister"
                              autoComplete="new-password"
                              placeholder="Confirm Password"
                              pattern=".*"
                              required
                              style={{ width: "90%" }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid Password.
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                      </Form.Group>
                      <div
                        className="d-grid gap-2 mt-3"
                        colSpan="2"
                        align="center"
                      >
                        <Button
                          type="submit"
                          className="btn btn-navy mx-auto"
                          name="registerButton"
                          ref={registerButtonRef}
                          value="Register"
                          onClick={() => handleSectionChange("register")}
                        >
                          Register
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row style={{ height: "20px" }}></Row>
          </div>
        )}
        {isRegistered && (
          <div id="verifyRegistrationSection">
            <div
              className="Auth-form-container   d-flex align-items-center justify-content-center"
              id="verifySection"
            >
              <form
                id="verifyForm"
                onSubmit={handleVerify}
                className="Auth-form"
              >
                <div className="Auth-form-content">
                  <h6 className="Auth-form-title">Verify Registration</h6>
                  <div className="form-group mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Email Address:</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          id="emailInputVerify"
                          className="form-control mt-1"
                          placeholder="Email"
                          required
                        />
                        <br />
                        <span
                          id="emailVerifyError"
                          style={{ color: "red" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Verification Code:</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          id="codeInputVerify"
                          className="form-control mt-1"
                          placeholder="Verification Code"
                          pattern=".*"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-grid gap-2 mt-3">
                    <button
                      name="verifyButton"
                      type="submit"
                      className="btn btn-navy mx-auto"
                      ref={verifyButtonRef}
                      value="Verify"
                    >
                      Verify
                    </button>
                  </div>{" "}
                </div>
              </form>
            </div>
          </div>
        )}
        {isAuthenticated && (
          <Container style={{ width: "100%" }}>
            <Row style={{ height: "10px" }}></Row>
            <Posts />
          </Container>
        )}
      </div>
    </>
  );
}

export default Forum;
