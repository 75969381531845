import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded) {
      const timer = setTimeout(() => setExpanded(false), 7000); // 5 seconds idle time
      return () => clearTimeout(timer);
    }
  }, [expanded]);

  return (
    <>
      <div className="header">
        <Navbar expanded={expanded} onToggle={() => setExpanded(!expanded)} expand="lg">
          <Navbar.Brand as={Link} to="/">
            <img className="logo" src="/images/bio-header.png" alt="Bio Inquiries" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav" className='justify-content-end'>
            <Nav>
              <Nav.Link as={Link} to="/about" onClick={() => setExpanded(false)}>
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/podcasts" onClick={() => setExpanded(false)}>
                Podcast
              </Nav.Link>
              <Nav.Link as={Link} to="/seminars" onClick={() => setExpanded(false)}>
                Seminars
              </Nav.Link>
              <Nav.Link as={Link} to="/forum" onClick={() => setExpanded(false)}>
                Forum
              </Nav.Link>
              <Nav.Link as={Link} to="/reachout" onClick={() => setExpanded(false)}>
                Reach Out
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className='row'>
        <div className="border-below"></div>
      </div>
    </>
  );
}

export default Header;
