import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import { getPodcastEpisodes } from './getEpisodes';

const Podcasts = () => {
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const showId = '0bTLEasg5UgnM50yENIs3H';

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const episodes = await getPodcastEpisodes(showId);
        setEpisodes(episodes);
      } catch (error) {
        console.error('Error fetching podcast episodes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEpisodes();
  }, [showId]);

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container>
      <Row style={{paddingTop:'10px'}}></Row>
      <Row>
        {episodes && episodes.map((episode, index) => (
          episode && <Col md={4} key={episode.id}>
            <Card className="mb-4">
              <Card.Body>
                <iframe
                  src={`https://open.spotify.com/embed/episode/${episode.id}`}
                  width="100%"
                  height="232"
                  frameBorder="0"
                  allow="encrypted-media"
                  title={`Podcast Episode ${index + 1}`}
                ></iframe>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Podcasts;
