import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SeminarsHeader = () => {
  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <h3 className="mb-3">Empowering Rural Indian Students through Biotechnology Seminars</h3>
          <p>
            Welcome to our Biotechnology Seminars, dedicated to educating and inspiring the bright minds of rural India. Our mission is to provide comprehensive education in the field of biotechnology, enabling students from rural backgrounds to pursue impactful careers in science and technology.
          </p>
          <p>
            Through a series of seminars, we aim to bridge the educational gap and foster innovation among young learners. Our seminars cover a wide range of topics, including genetic engineering, sustainable agriculture, healthcare advancements, and environmental conservation, all tailored to address the unique challenges and opportunities within rural communities.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default SeminarsHeader;
