import React from 'react';
import ContentCard from './ContentCard';
import Carousel from '../../Corousel/Corousel';
import './ContentCard.css';

function Main() {
  return (
    <>
          <div className="carousel-container">
        <Carousel />
      </div>

        <div className="content-card">
        <ContentCard
        imageSrc="images/iii.png"
        title="BIOINQUIRIES: Exploring Biotechnological Advancements"
        description="Biotechnology encompasses many contributions in countless industries. From the food we eat and how we source them, the clothes we wear and how we manufacture them, to the medicine we take and how we discover them, biotechnological developments have deep-rooted prolonged implications on how we live. Through exploring ideas, inventions, and innovations, I decided to research, compile, and inquire about coming and pre-existing biotechnology in the hopes of understanding what shapes our lives.<br /><br />

        The scientific method of discovery begins with Ideas, and this podcast will showcase the modern thinking and pioneers that enabled the coming of new Inventions and Innovations. Inventions begin with new concepts and approaches to pre-existing problems while innovations end with ingenious developments to pre-existing solutions.  This project is meant to help us understand not only why we innovate, but also how these discoveries can change our collective future.
        <br /><br />
        The common purpose of this project is to understand biotechnologies as solutions to the various disparities present in countless industries. The hope is to inquire about solutions that are more efficient, more cost-effective, more conscious of the environment, low risk, or any other means. Thank you for joining me on my journey!"
      />
                    {}
                </div>
                </>
  );
}

export default Main;
