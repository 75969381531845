import React from 'react';
import './ContentCard.css';

const ContentCard = ({ imageSrc, title, description }) => {
    const createMarkup = (htmlString) => ({ __html: htmlString });

    return (
            <div className="rectangle-container row no-gutters">
              <div className="rectangle-image col-4 d-flex align-items-center justify-content-center">
                <div className='card'> <img src={imageSrc} alt={title} className="img-fluid" /></div>
              </div>
              <div className="rectangle-content col-8 p-4">
                <p dangerouslySetInnerHTML={createMarkup(description)} />
              </div>
            </div>
          
    );
  };

  export default ContentCard;