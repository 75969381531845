import axios from 'axios';
const authUrl = 'https://accounts.spotify.com/api/token';
const apiUrl = 'https://api.spotify.com/v1';

const getToken = async () => {
  const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
  const response = await axios.post(authUrl, 'grant_type=client_credentials', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
    },
  });
  return response.data.access_token;
};

const getPodcastEpisodes = async (showId) => {
  const token = await getToken();
  let episodes = [];
  let offset = 0;
  const limit = 50;  // Maximum limit allowed by the API is 50

  let hasMoreEpisodes = true;

  while (hasMoreEpisodes) {
    const response = await axios.get(`${apiUrl}/shows/${showId}/episodes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit,
        offset,
      },
    });

    episodes = [...episodes, ...response.data.items];

    // Check if there are more episodes to fetch
    if (response.data.items.length < limit) {
      hasMoreEpisodes = false;
    } else {
      offset += limit;  // Move to the next batch
    }
  }

  return episodes;
};

export { getPodcastEpisodes };
