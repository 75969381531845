import React, { createContext, useContext, useState, useEffect } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [uniqueUserId, setUniqueUserId] = useState('');
  const [authToken, setAuthToken] = useState('');

  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
  });

  const fetchAuthToken = async () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      try {
        const session = await new Promise((resolve, reject) => {
          cognitoUser.getSession((err, session) => {
            if (err) {
              reject(err);
            } else {
              resolve(session);
            }
          });
        });

        const attributes = await new Promise((resolve, reject) => {
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              reject(err);
            } else {
              resolve(attributes);
            }
          });
        });

        const nameAttribute = attributes.find((attribute) => attribute.getName() === 'name');
        const emailAttribute = attributes.find((attribute) => attribute.getName() === 'email');
        const uniqueUserIdAttribute = attributes.find((attribute) => attribute.getName() === 'sub');

        setName(nameAttribute ? nameAttribute.getValue() : '');
        setEmail(emailAttribute ? emailAttribute.getValue() : '');
        setUniqueUserId(uniqueUserIdAttribute ? uniqueUserIdAttribute.getValue() : '');

        setAuthToken(session.getIdToken().getJwtToken());
      } catch (error) {
        console.error('Error fetching authentication token:', error);
      }
    }
  };

  useEffect(() => {
    fetchAuthToken(); // Fetch the authentication token when the component mounts
  }, []);

  return (
    <AuthContext.Provider value={{ name, email, uniqueUserId, authToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
