import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css'

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 50,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    touchThreshold: 1000
  };

  return (
    <Slider {...settings}>
      <div>
        <img src="images/carousel/image1.jpg" alt="Slide 1" />
      </div>
      <div>
        <img src="images/carousel/image2.jpg" alt="Slide 2" />
      </div>
      <div>
        <img src="images/carousel/image3.jpg" alt="Slide 3" />
      </div>
      {/* Add more slides as needed */}
    </Slider>
  );
};

export default Carousel;
