import React from "react";
import {Button,Container} from 'react-bootstrap';

const Posts=()=>{
    return(
        <div id="postsSection Auth-form-container   d-flex align-items-center justify-content-center"  style={{width:'100%',alignContent:'center',alignItems:'center'}}>
        <Container>
            <h4 className="mb-3">Community Corner: Bio Inquiries Forum
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button
                    id="createPostButton" className="btn btn-navy mx-auto" style={{paddingLeft:'10px',paddingRight:'10px'}}>Create Post</Button></h4>
            <div id="posts-container"></div>
        </Container>

        <div id="commentModal" className="modal">
            <div className="modal-content">
                <span className="close">&times;</span>
                <h4 className="col-md-6">Add a Comment</h4>
                <input type="text" id="commentInput" className="form-control mt-1" placeholder="Enter your comment" />
                <Button id="addCommentButton" className="btn btn-navy mx-auto"
                >Add Comment</Button>
            </div>
        </div>

        <div id="postModal" className="modal">
            <div className="modal-content">
                <span className="close">&times;</span>
                <h4 className="mb-3">Create a Post</h4>
                <p className="col-md-6">Title:<br /><input type="text" className="form-control mt-1" id="titleInput" style={{width: '90%'}}
                        placeholder="Enter post title" /></p>
                <p className="col-md-6">Description:<br /><textarea id="contentInput" className="form-control mt-1" rows="3" style={{width: '90%'}}
                        placeholder="Enter post description"></textarea></p>
                <p><Button id="addPostButton" className="btn btn-navy mx-auto">Add Post</Button></p>
            </div>
        </div>
    </div>
    );
};

export default Posts;
