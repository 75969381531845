import $ from 'jquery';
import { useAuth } from './AuthContext';
import { CognitoUserPool, CognitoUser,AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';


export const CognitoAuth=({ setIsRegistered, setValidatedSignin, setValidatedRegister })=>{
    const { name, email, uniqueUserId } = useAuth();
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
      });

      function register(email, name, password, onSuccess, onFailure) {
        var dataName = {
            Name: 'name',
            Value: name
        };
        var attributeName = new CognitoUserAttribute(dataName);

        userPool.signUp(email, password, [attributeName], null,
            function signUpCallback(err, result) {
                if (!err) {
                    onSuccess(result);
                } else {
                    onFailure(err);
                }
            }
        );
    }
     function createCognitoUser(email) {
        return new CognitoUser({
            Username: email,
            Pool: userPool
        });
    }
     function signin(email, password, onSuccess, onFailure) {
        var authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        var cognitoUser = createCognitoUser(email);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: onSuccess,
            onFailure: onFailure
        });
    }
     function verify(email, code, onSuccess, onFailure) {
        createCognitoUser(email).confirmRegistration(code, true, function confirmCallback(err, result) {
            if (!err) {
                onSuccess(result);
            } else {
                onFailure(err);
            }
        });
    }
    const signOut = () => {
        userPool.getCurrentUser().signOut();
      };
//End of Javascript
 function handleSignin(event) {
    var email = $('#emailInputSignin').val();
    var password = $('#passwordInputSignin').val();
    event.preventDefault();

    signin(email, password,
        function signinSuccess() {
            console.log('Successfully Logged In');
            var sectionsToHide = ["#signInSection", "#registerSection", "#notAuthenticatedSection", "#verifySection", "#verifyRegistrationSection"];
            var elementsToEnableAndClear = [];
            var elementsToDisableAndClear = ["#signInSection", "#registerSection", "#verifySection"];
            var sectionsToShow = ["#postsSection"];
            var errorElementsToClear = ["#emailSigninError", "#emailRegisterError", "#emailVerifyError"];

            setPageElements(sectionsToHide, elementsToEnableAndClear, elementsToDisableAndClear, sectionsToShow, errorElementsToClear);
            if(name == null)
            {
              console.log('reloading the page');
            }
            else {
              console.log('no reloading the page');
            }
            window.location.reload();
            loadPosts();
        },
        function signinError(err) {
          if(email!="" && password!=""){
            if (err && err.code && err.code.indexOf("UserNotConfirmedException") !== -1) {
              var sectionsToHide = ["#signInSection", "#registerSection", "#notAuthenticatedSection"];
              var elementsToEnableAndClear = [ "#verifySection"];
              var elementsToDisableAndClear = ["#signInSection", "#registerSection"];
              var sectionsToShow = ["#verifySection", "#verifyRegistrationSection"];
              var errorElementsToClear = ["#emailSigninError", "#emailRegisterError", "#emailVerifyError", "#passwordInputSignin"];
              setIsRegistered(true);
              setValidatedSignin(false);
              setValidatedRegister(false);
              setPageElements(sectionsToHide, elementsToEnableAndClear, elementsToDisableAndClear, sectionsToShow, errorElementsToClear);
            }
            else {
              alert(err);
            }
          }
        }
    );
}


 function handleVerify(event) {
    var email = $('#emailInputVerify').val();
    var code = $('#codeInputVerify').val();
    event.preventDefault();
    verify(email, code,
        function verifySuccess(result) {
            alert('Verification is successful. Please SignIn with your credentails.');

            var sectionsToHide = ["#verifySection", "#verifyRegistrationSection", "#postsSection"];
            var elementsToEnableAndClear = ["#signInSection", "#registerSection"];
            var elementsToDisableAndClear = ["#verifySection"];
            var sectionsToShow = ["#signInSection", "#registerSection", "#notAuthenticatedSection"];
            var errorElementsToClear = ["#emailSigninError", "#emailRegisterError", "#emailVerifyError", "#passwordInputSignin"];
            setIsRegistered(true);
            setValidatedSignin(false);
            setValidatedRegister(false);
            setPageElements(sectionsToHide, elementsToEnableAndClear, elementsToDisableAndClear, sectionsToShow, errorElementsToClear);
        },
        function verifyError(err) {
            alert(err);
        }
    );
}
 function setPageElements(sectionsToHide, elementsToEnableAndClear, elementsToDisableAndClear, sectionsToShow, errorElementsToClear)
{
    sectionsToHide.forEach(section => {
        $(section).hide();
    });

    elementsToDisableAndClear.forEach(element => {
        var $element = $(element);
        $element.find(":input").prop("disabled", true);
        $element.find(":input:not([type='submit'])").val("");
    });

    elementsToEnableAndClear.forEach(element => {
      var $element = $(element);
      $element.find(":input").prop("disabled", false);
      $element.find(":input:not([type='submit'])").val("");
    });


    sectionsToShow.forEach(section => {
        $(section).show();
    });

    errorElementsToClear.forEach(errorElement => {
        var $errorElement = $(errorElement);
        $errorElement.text("");
    });
}

 function loadPosts() {
    var headers = {
        "Accept": "application/json",
        "x-api-key": process.env.REACT_APP_API_POSTS_KEY
    };

    var apiEndpoint =process.env.REACT_APP_API_POSTS_INVOKE_URL;
    var posts = [];

    $.ajax({
      type: "GET",
        url: apiEndpoint,
        headers: headers,
        data: "",
        contentType: "application/json",
        success: function(response) {
            console.log(response);
            if(response){
          var data = response.customMessage.result.data;}

          try {
            posts = JSON.parse(data);
          } catch (e) {
            console.error("Error parsing JSON:", e);
            return;
          }

          displayPostsWithVotes(posts);
        },
        error: function (error) {
            console.error("API error:", error);
            alert("Error calling the Posts API. Please try again later.");
        },
        complete: function (response) {
            response = {
                statusCode: 200,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
                    'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
                },
            };
        }
    });
}

 function displayPostsWithVotes(posts) {
  const postsContainer = $("#posts-container");
  postsContainer.empty();
  posts.forEach(post => {
      const postHTML = `
          <div class="post">
              <h4 className="col-md-6">${post.Title}</h4>
              <p className="col-md-6">${post.Content}</p>
              <p><h9 className="col-md-6">Posted by: ${post.UserName}.&nbsp;&nbsp;${formatTimestamp(post.Timestamp)}</h9></p>
              <div class="votes">
                  <div class="upvote-button">
                      <span>${post.Upvotes}&nbsp;<img src="images/upvotes.png" alt="Upvote" upv-data-postid="${post.PostID}"></span>
                  </div>
                  <div class="downvote-button">
                      <span>${post.Downvotes}&nbsp;<img src="images/downvotes.png" alt="Downvote" downv-data-postid="${post.PostID}"></span>
                  </div>
              </div>
              <div>&nbsp;</div>
              <div style="padding-left: 20px;"><h4 className="col-md-6">Comments:</h4></div>
              <div className="col-md-6" style="padding-left: 20px;" id="comments-${post.PostID}">
                ${post.Comments.length > 0 ? displayComments(post.Comments) : "No comments yet."}
              </div>
              <p></p>
              <div class="comments-container"><button class="btn btn-navy comment-button" comm-data-postid="${post.PostID}">Add Comment</button></div>
          </div>
      `;
      postsContainer.append(postHTML);

      const commentButton = $(`[comm-data-postid="${post.PostID}"]`);
      commentButton.click(() => openCommentModal(post.PostID));

      const upvButton = $(`[upv-data-postid="${post.PostID}"]`);
      upvButton.click(() => upvotePost(post.PostID));

      const downvButton = $(`[downv-data-postid="${post.PostID}"]`);
      downvButton.click(() => downvotePost(post.PostID));
  });
}

 function displayComments(comments) {
  let commentsHTML = "";
  comments.forEach(comment => {
    commentsHTML += `
      <div class="comment">
        <p><h9 className="col-md-6">${comment.username}.&nbsp;&nbsp;${formatTimestamp(comment.timestamp)}</h9></p>
        <p className="col-md-6">${comment.comment}</p>
      </div>
    `;
  });
  return commentsHTML;
}

 function formatTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  return date.toLocaleString('en-US', options);
}

 function openCommentModal(postId) {
  const modal = $("#commentModal");
  modal.css("display", "block");
  const commentInput = $("#commentInput");
  const addCommentButton = $("#addCommentButton");

  addCommentButton.click(() => {
      const commentVal = commentInput.val();
      if (commentVal) {
        var headers = {
            "Accept": "application/json",
            "x-api-key": process.env.REACT_APP_API_POSTS_KEY
        };

        var apiEndpoint = `${process.env.REACT_APP_API_POSTS_INVOKE_URL}/${postId}/createcomment`;

        $.ajax({
            url: apiEndpoint,
            type: 'PUT',
            headers: headers,
            contentType: "application/json",
            data: JSON.stringify({ comment: commentVal,
            uniqueuserid: uniqueUserId,
            username: name,
            useremail: email}),
            success: function (response) {
                alert('Added comment successfully');
                loadPosts();
            },
            error: function (error) {
                alert('Failed to add comment');
            }
          });

          addCommentButton.prop("disabled", true);
          closeCommentModal();
      }
  });

  const closeButton = $(".close");
  closeButton.click(closeCommentModal);
}

 function closeCommentModal() {
  const modal = $("#commentModal");
  const commentInput = $("#commentInput");
  const addCommentButton = $("#addCommentButton");

  commentInput.val("");
  addCommentButton.prop("disabled", false);
  modal.css("display", "none");
}

function upvotePost(postId) {
    var headers = {
        "Accept": "application/json",
        "x-api-key": process.env.REACT_APP_API_POSTS_KEY
    };

    var apiEndpoint = `${process.env.REACT_APP_API_POSTS_INVOKE_URL}/${postId}/upvote`;

    $.ajax({
        url: apiEndpoint,
        type: 'PUT',
        headers: headers,
        contentType: "application/json",
        data: JSON.stringify({ up_votes: "True" }),
        success: function (response) {
            loadPosts();
        },
        error: function (error) {
            alert('Failed to upvote');
        }
    });
}
 function downvotePost(postId) {
    var headers = {
        "Accept": "application/json",
        "x-api-key": process.env.REACT_APP_API_POSTS_KEY
    };

    var apiEndpoint = `${process.env.REACT_APP_API_POSTS_INVOKE_URL}/${postId}/downvote`;

    $.ajax({
        url: apiEndpoint,
        type: 'PUT',
        headers: headers,
        contentType: "application/json",
        data: JSON.stringify({ down_votes: "True" }),
        success: function (response) {
            loadPosts();
        },
        error: function (error) {
            alert('Failed to downvote');
        }
    });
}

 function openPostModal() {
  const modal = $("#postModal");
  modal.css("display", "block");
  const contentInput = $("#contentInput");
  const titleInput = $("#titleInput");
  const addPostButton = $("#addPostButton");

  addPostButton.click(() => {
      const titleVal = titleInput.val();
      const contentVal = contentInput.val();
      if (titleVal && contentVal) {
        var headers = {
            "Accept": "application/json",
            "x-api-key": process.env.REACT_APP_API_POSTS_KEY
        };

        var apiEndpoint = process.env.REACT_APP_API_POSTS_INVOKE_URL;
        console.log('BioInquiry' + JSON.stringify(name));
        $.ajax({
            url: apiEndpoint,
            type: 'POST',
            headers: headers,
            contentType: "application/json",
            data: JSON.stringify({ title: titleVal,
            content: contentVal,
            uniqueuserid: uniqueUserId,
            username: name,
            useremail: email}),
            success: function (response) {
                alert('Added Post successfully');
                loadPosts();
            },
            error: function (error) {
                alert('Failed to add post');
            }
          });

          addPostButton.prop("disabled", true);
          closePostModal();
      }
  });

  const closeButton = $(".close");
  closeButton.click(closePostModal);
}

  function closePostModal() {
  const modal = $("#postModal");
  const postInput = $("#postInput");
  const createPostButton = $("#createPostButton");

  postInput.val("");
  createPostButton.prop("disabled", false);
  modal.css("display", "none");
}
return {handleVerify,handleSignin,loadPosts,displayPostsWithVotes,displayComments,formatTimestamp,openCommentModal,closeCommentModal,openPostModal,closePostModal,register,createCognitoUser,signin,downvotePost,upvotePost,setPageElements};
}
