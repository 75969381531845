import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './Home/MainPage/Main';
import Forum from './Forum/Forum';
import Podcasts from './Podcasts/Podcasts';
import Reachout from './Reachout/Reachout';
import Seminars from './Seminars/Seminars';
import PrivateRoute from './Forum/PrivateRoute';

function AppRouter() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/forum" element={<Forum />} />
        <Route exact path="/about" element={<Main />} />
        <Route exact path="/podcasts" element={<Podcasts />} />
        <Route exact path="/seminars" element={<Seminars />} />
        <Route exact path="/reachout" element={<Reachout />} />
      </Routes>
      </>
  );
}

export default AppRouter;
