import React from 'react';
import './App.css';
import AppRouter from './AppRouter';
import Header from './Header/Header';
import Footer from './Footer/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <div className='main-container'>
            <AppRouter />
      </div>
      <div className='footer-main'>
      <Footer /></div>
    </div>
  );
}

export default App;
