import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import SeminarsHeader from './SeminarsHeader';

const Seminars = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const CHANNEL_ID = process.env.REACT_APP_GOOGLE_CHANNEL_ID;

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=10`
        );
        setVideos(response.data.items);
      } catch (error) {
        console.error('Error fetching YouTube videos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [CHANNEL_ID]);

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container>
      {/* Header Section */}
      <SeminarsHeader />

      {/* Pictures Section */}
      <h3 className="mt-4 mb-4" style={{ textAlign: 'left' }}>Photographs:</h3>
      <Row>
        {['/images/seminars/Seminar-Pic1.jpg', '/images/seminars/Seminar-Pic2.jpg', '/images/seminars/Seminar-Pic3.jpg', '/images/seminars/Seminar-Pic4.jpg'].map((pic, index) => (
          <Col md={6} key={index} className="mb-4">
            <img src={pic} alt="Bio Inquiries-Seminars" style={{ width: '400px', height: '300px', objectFit: 'cover' }} />
          </Col>
        ))}
      </Row>

      {/* Video Section */}
      <h3 className="mt-4 mb-4" style={{ textAlign: 'left' }}>Video Sessions:</h3>
      <Row>
        {videos && videos.map((video, index) => (
          video.id.videoId && <Col md={4} key={video.id.videoId}>
            <Card className="mb-4">
              <Card.Body>
                <iframe
                  src={`https://www.youtube.com/embed/${video.id.videoId}?rel=0`}
                  width="100%"
                  height="232"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={`YouTube Video ${index + 1}`}
                />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Seminars;
